import * as React from "react"

export default function Page() {
    return (
        <div className="legal-page relative bg-darker py-24">
            <div className="container">
                <div className="w-full lg:w-2/3 mx-auto cl-50 z-ind">
                    <h1 className="text-white"><span className="cl-primary">Pricing</span> &amp; Structure.</h1>
                    <h4>HOURLY RATES</h4>
                    <p>We charge an hourly rate for the following services:</p>
                    <ul>
                        <li>Monthly retainers  </li>
                        <li>Ad-hoc development and maintenance work  </li>
                        <li>Consultation and troubleshooting</li>
                    </ul>
                    <p>Hourly rates may vary based on the complexity and nature of the task. Clients will be informed of applicable rates before work begins.</p>
                    <h4>PROJECT BASED FEES</h4>
                    <p>For full website development, redesigns, or other structured projects, we provide a fixed project-based quote. These quotes are based on:</p>
                    <ul>
                        <li>Scope of work  </li>
                        <li>Complexity of the project  </li>
                        <li>Estimated development time</li>
                    </ul>
                    <p>The project-based fee will be outlined in a proposal before commencement. Itemized breakdowns are available upon request.</p>
                    <h4>HOSTING & DOMAIN SERVICES</h4>
                    <ul>
                        <li>Web & Email Hosting: Billed either monthly or annually, based on the selected plan.  </li>
                        <li>Domain Renewals: Charged annually.</li>
                    </ul>
                    <h4>VAT & INTERNATIONAL CLIENTS</h4>
                    <p>We are VAT registered and charge VAT for South African clients. International clients are not subject to VAT unless required by local regulations.</p>
                    <p>VAT Registration Number: 4140306913</p>
                    <p>If you have any questions regarding our pricing policy, please contact us at <a href="mailto:info@artificial.studio">info@artificial.studio</a></p>
                </div>
            </div>
            <div className="top-gradient-dark"></div>
        </div>
    )
}
